import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import { getAccessToken } from './cookie'
import router from '../router'
const axiosIns = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/api/v1`,
  timeout: 18000,
  headers: {

  },
})

const noti_types = {
  'SUCCESS': 'pushSuccessNotify',
  'WARN': 'pushWarningNotify',
  'ERROR': 'pushErrorNotify',
  'INFO': 'pushInfoNotify',
}

const error_key_map = new Map([
  ['unauthorized', `You don't have permission to do this action`],
  ['existed_lesson_found_on_this_path', `This learning path has lessons. Please delete all lessons of this learning path before deleting the learning path.`],
  ['existed_word_found_on_lessons', `This word has been added to a lesson. Please replace it with another word before deleting it`],
]);

axiosIns.interceptors.response.use(response => {
  if (['put', 'patch', 'post', 'delete'].includes(response.config.method) && ['SUCCESS', 'DATA'].includes(response.data.type) && !response.config.disable_notify) {
    store.dispatch('pushSuccessNotify', { text: `successfully ${response.config.method} data` })
  }
  if (response.data.type == 'MESSAGE') {
    if (!response.config.disable_notify){
      store.dispatch(noti_types[response.data.level] || 'pushInfoNotify', { text: `${response.data.key}` })
    }
    if (response.data.key === 'unauthenticated') {
      return router.push({ name: 'auth-login' })
    } else if (response.data.level === 'ERROR') {
        if (response.disable_notify){
          let error = new Error(response.data.key);
          error.data = response;
          throw error;
        } else {
          store.dispatch('pushErrorNotify', { text: `${error_key_map.get(response.data.key) || response.data.key}` });
        }
      }
    }
  return response
}, (error) => {
  let message = error?.response?.data?.message || 'request timeout!'
  let status_code = error?.response?.status || 'NaN'
  store.dispatch('pushErrorNotify', { text: `${status_code} ${message}` })
  return Promise.reject(error)
})

axiosIns.interceptors.request.use((config) => {
  // eslint-disable-next-line no-param-reassign
  config.headers = { Authorization: `Bearer ${getAccessToken()}` }
  return config
})

Vue.prototype.$http = axiosIns

export default axiosIns
