/* eslint-disable no-param-reassign */
import { v4 as uuidv4 } from 'uuid'
import omitBy from 'lodash/omitBy';
import debounce from 'lodash/debounce';

export { omitBy, debounce }

export function resetObject(obj, sampleObj) {
  let properties = Object.keys(sampleObj)
  for (let i = 0, length = properties.length; i < length; i += 1) {
    let key = properties[i]
    obj[key] = sampleObj[key]
  }
}

export function safeGet(cb) {
  try {
    return cb()
  } catch (err) { }
}

export function trueTypeOf(obj) {
  return Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();
}


export function buildSelectOptions(obj, options = {keyAsValue: false}) {
  let variants = {
    true: 'primary',
    false: 'danger',
    default: 'secondary',
  };
  if (options.keyAsValue){
    return Object.entries(obj).map(([key, value]) => {
      return { value: key, text: value, variant: variants[value] || variants.default };
    });
  }
  return Object.entries(obj).map(([key, value]) => {
    return { value, text: key, variant: variants[value] || variants.default };
  });
}

export function createUid() {
  return uuidv4();
}

export function removeEmpty(obj) {
  return omitBy(obj, v => v === null || v === '');
}

export function delay(mili_secs) {
  return new Promise((resovle) => { setTimeout(() => { resovle(true) }, mili_secs) });
}

export function transformTranslatableText(data, language){
  if (!data){
    return ''
  }
  let trans = data.translations.find((x)=>{
    return x.language == language
  })
  return trans ? trans.value : ""
}

export function objectToURLQuery(query_obj) {
  return encodeURIComponent(JSON.stringify(removeEmpty(query_obj)));
}

export default undefined
